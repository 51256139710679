import React from "react";
import { Link } from "gatsby";

const NewBlogSnippet = ({ post }) => {
  return (
    <div className="flex w-72 h-56 my-4 overflow-hidden">
      <article className="flex flex-col">
        <header>
          <p className="post-meta flex flex-col">
            <Link
              className="text-xl text-sunstate-blue mb"
              to={post.fields.slug}
            >
              {post.frontmatter.title}
            </Link>
            <span className="text-lg text-gray-700 mb-2">
              {post.frontmatter.date}
            </span>
          </p>
        </header>
        <p
          className="text-md text-gray-600"
          style={{ textOverflow: "ellipsis" }}
        >
          {post.excerpt}
        </p>
      </article>
    </div>
  );
};

export default NewBlogSnippet;
