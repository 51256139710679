import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import NewBlogSnippet from "./NewBlogSnippet";

const NewBlogSnippets = (props) => {
  const data = useStaticQuery(graphql`
    query SnippetQuery {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      ) {
        edges {
          node {
            excerpt(pruneLength: 200)
            id
            fields {
              slug
            }
            frontmatter {
              title
              templateKey
              date(formatString: "MMMM DD, YYYY")
              featuredpost
              featuredimage {
                childImageSharp {
                  fluid(maxWidth: 120, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const { edges: posts } = data.allMarkdownRemark;

  return (
    <div className="w-full bg-white flex justify-center items-center">
      <div className="w-2/3 flex flex-row flex-wrap justify-around items-center py-16">
        {posts &&
          posts.map(({ node: post }) => (
            <NewBlogSnippet key={post.id} post={post} />
          ))}
      </div>
    </div>
  );
};

NewBlogSnippets.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default NewBlogSnippets;
