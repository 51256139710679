import React from "react";
import Layout from "../../components/Layout";
import NewBlogSnippets from "../../components/NewBlogSnippets";

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <NewBlogSnippets />
      </Layout>
    );
  }
}
